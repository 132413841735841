<template>
  <div class="modal-password-container" @click.self="$emit('close')">
    <div class="modal-password-modal-wrap">
      <div class="modal-password-modal-header">
        <span class="modal-password-text">{{ title }}</span>
        <img @click="$emit('close')" :src="image_src1" :alt="image_alt1" class="modal-password-image cursor-pointer" />
      </div>
      <span class="modal-password-text1">{{ desc }}</span>
     <div class="row-button">
        <div class="modal-password-btn-login cancel" @click="$emit('close')">
          <span class="modal-password-text2 cancel-text">{{ text }}</span>
        </div>
        <div class="modal-password-btn-login" @click="$emit('confirm')">
          <span class="modal-password-text2">{{ text2 }}</span>
      </div>
     </div>
    </div>
  </div>
</template>

<script>
import InputBox from './input-box'

export default {
  name: 'ModalYesNo',
  props: {
    title: {
      type: String,
      default: 'Send Review',
    },
    image_src1: {
      type: String,
      default: '/playground_assets/x_close.svg',
    },
    image_alt1: {
      type: String,
      default: 'image',
    },
    desc: {
      type: String,
      default:
        'Are you sure you are going to send this review?',
    },
    text: {
      type: String,
      default: 'Cancel',
    },
    text2: {
      type: String,
      default: 'Send',
    },
    // onClose: { type: Function },
  },
  components: {
    InputBox,
  },
  methods: {
    closeModal(){
      this.$root.$emit('onClose');
    },
    openSukses(){
      this.$root.$emit('onClose');
      this.$root.$emit('onSukses');
    },
  }
}
</script>

<style scoped>
.row-button{
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.modal-password-container {
  width: 100%;
  height: 100vh;
  display: flex;
  z-index: 100;
  position: fixed;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
}
.modal-password-modal-wrap {
  flex: 0 0 auto;
  width: 500px;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  align-items: flex-start;
  flex-direction: column;
  background-color: #fff;
  z-index: 101;
}
.modal-password-modal-header {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: space-between;
}
.modal-password-text {
  font-size: 2em;
  font-style: normal;
  font-family: Lora;
  font-weight: 700;
}
.modal-password-image {
  width: 46px;
  object-fit: cover;
}
.modal-password-text1 {
  font-family: Montserrat;
  line-height: 2;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.modal-password-btn-login {
  flex: 0 0 auto;
  width: 48%;
  cursor: pointer;
  height: 64px;
  display: flex;
  position: relative;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  justify-content: flex-start;
  background-color: #1F252C;
}

.modal-password-btn-login.cancel {
  flex: 0 0 auto;
  width: 48%;
  cursor: pointer;
  height: 64px;
  display: flex;
  position: relative;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  justify-content: flex-start;
  background-color: #fff;
  border: 1px solid #1F252C;
}
.modal-password-text2 {
  color: #fff;
  width: 100%;
  font-size: 18px;
  font-style: normal;
  text-align: center;
  font-family: Montserrat;
  font-weight: 700;
  letter-spacing: 2.4px;
}

.modal-password-text2.cancel-text {
  color: #1F252C;
  width: 100%;
  font-size: 18px;
  font-style: normal;
  text-align: center;
  font-family: Montserrat;
  font-weight: 700;
  letter-spacing: 2.4px;
}
@media(max-width: 991px) {
  .modal-password-modal-wrap {
    width: 100%;
    height: auto;
  }
}
@media(max-width: 479px) {
  .modal-password-modal-wrap {
    width: 100%;
  }
}
</style>
