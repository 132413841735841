<template>
  <div class="input-area-input-box" v-bind:class="rootClassName">
    <span class="input-area-text">{{ text }}</span>
    <textarea
      :disabled="disabled"
      :placeholder="textinputPlaceholder"
      class="input-area-textinput input"
      v-bind:value="value"
      v-on:input="$emit('input', $event.target.value)"
    />
  </div>
</template>

<script>
export default {
  name: 'InputArea',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    rootClassName: String,
    text: {
      type: String,
      default: 'Your Review​',
    },
    value:{
      type: String,
    },
    textinputPlaceholder: {
      type: String,
      default: 'Type your review here...',
    },
  },
}
</script>

<style scoped>
.input-area-input-box {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: column;
}
.input-area-text {
  font-size: 18px;
  font-style: normal;
  font-family: Lora;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-halfunit);
}
.input-area-textinput {
  width: 100%;
  height: var(--dl-size-size-large);
  font-size: 16px;
  font-family: Montserrat;
  border:1px solid #1F252C;
  padding-top: 16px;
}

</style>
